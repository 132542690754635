// Swedish
export default{
    form: {
        startSurveyButton: "Låt oss börja",
        nextButton: "Nästa",
        submitAnswerButton: "Skicka svar",
        continueButton: "Fortsätt",
        finishButton: "Avsluta",
        headerPercentageCompletion: 'slutfört',
        headerQuestionsCompleted: 'frågor',
        headerDefaultErrorMessage: 'Oj! Något gick fel.',
        brandingPoweredBy: 'Drivs av',
        brandingPromoWhySurvey: 'Varför göra en enkät när du kan använda <b>{product}</b>?',
        brandingPromoGetStarted: 'Kom igång idag!',
        submissionPending: "Din voiceform skickas in. Vänligen stäng inte webbläsarfönstret.",
        submissionCanCloseWindow: "Din voiceform har skickats in. Du kan nu stänga fönstret 👍",
        mediaAttachmentOverlayCloseButton: "Stäng",
        expiredFormHeader: "Tack för att du tittade förbi!",
        expiredFormMessage: "Det verkar som om den här enkäten har gått ut. Kontakta gärna skaparen av voiceform för att meddela detta.",
        notPublishedFormHeader: "Voiceform är inte publicerad!",
        notPublishedFormMessage: "Det verkar som om denna enkät inte har publicerats. Kontakta skaparen av voiceform för att meddela detta.",
        notPublishedFormRetryButton: "Försök igen",
        errorFormHeader: "Oj!",
        errorFormMessage: "Något kan ha gått fel. Vänligen försök igen senare.",
        errorFormRetryButton: "Försök igen",
        emptyFormHeader: "Tack för att du tittade förbi!",
        emptyFormMessage: "Oj! Det verkar som om detta formulär för närvarande är tomt utan några frågor eller fält att fylla i. Vi ber om ursäkt för besväret. Vänligen kontakta skaparen av detta formulär för att informera om problemet. Tack för din förståelse och ditt samarbete!",

        restoreFormHeader: "Det verkar som om du har ett pågående formulär!",
        restoreFormMessage: "Vill du fortsätta där du slutade?",
        restoreFormButton: "Fortsätt formulär",
        restoreFormButtonNew: "Börja om",

        audioPermissionHeader: "Vill du svara med röst?",
        audioPermissionMessage: "Att prata gör det snabbt och enkelt att svara.",
        audioPermissionAcceptButton: "Ja, det vill jag",
        audioPermissionDenyButton: "Nej tack",
        audioPermissionErrorHeader: "Mikrofonen är inte tillgänglig",
        audioPermissionErrorMessage: "Kontrollera webbläsarens behörigheter för att aktivera åtkomst till mikrofonen.",
        audioPermissionErrorButton: "Okej",
        audioRecorderFailedUpload: "Misslyckades med att ladda upp inspelningen",
        silenceDetectorError: "Vi kan inte höra dig. Kontrollera dina mikrofoninställningar. Tips: Det kan vara din externa mikrofon.",
        audioRecorderButtonRecord: "Spela in",
        audioRecorderButtonRecordMore: "Spela in mer",
        voiceResponseSelectionMessage: "Välj hur du vill svara...",
        voiceResponseSelectionOrDivider: "eller",
        voiceResponseTextInputPlaceholder: "Skriv svar",
        voiceResponseTextInputCharacterCounterMinMessage: "Vänligen ange minst {min} tecken",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} tecken (minst {min} tecken)",
        voiceResponseTextInputCharacterValidationMessage: "{count} tecken (minst {min} tecken)",

        // checkbox
        checkboxValidationTooFew: "Du måste välja minst {min} alternativ",
        checkboxValidationTooMany: "Du får välja högst {max} alternativ",
        checkboxNoneOfTheAboveOption: "Inget av ovanstående",
        checkboxInvalidAnswer: "Ogiltigt svar.",

        // datepicker
        datePickerPlaceholder: "Välj datum",

        // dropdown
        // email
        emailLabel: "E-post",
        emailInvalid: "Ogiltig e-post.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Spela in video",
        fileUploadPluginScreenCast: "Skärminspelning",
        fileUploadVideoImportFilesDefault: 'Spela in eller ladda upp en video via:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Välj ett videoalternativ:',
        fileUploadVideoImportFilesNoCamera: 'Tryck på en knapp för att spela in skärmvideo',
        fileUploadVideoImportFilesNoScreenCast: 'Tryck på en knapp för att spela in video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Inga alternativ tillgängliga för att spela in video',

        // matrix
        matrixValidationMessage: "Svaret är giltigt.",
        matrixRow: "Rad",
        matrixColumn: "Kolumn",
        matrixRowRequired: "Raden {rowTitle} är obligatorisk.",
        matrixRadioGroup: "Radiogrupp",
        matrixCheckboxGroup: "Kryssrutegrupp",
        matrixGroupRequired: "För raden {rowTitle}. {type} {groupTitle} är obligatorisk.",
        matrixColumnRequired: "För raden {rowTitle}. Kolumnen {columnTitle} är obligatorisk.",
        matrixColumnInvalid: "För raden {rowTitle}. Kolumnen {columnTitle} är ogiltig.",
        matrixRequired: "Obligatoriskt.",
        matrixNumericMustBeNumber: "Måste vara ett nummer.",
        matrixNumericMustBeGreaterThenMin: "Måste vara större än {min}.",
        matrixNumericMustBeLessThenMax: "Måste vara mindre än {max}.",
        matrixNumericMustBeInteger: "Måste vara ett heltal.",
        matrixNumericInvalidNumber: "Ogiltigt nummer.",

        // name
        nameLabel: "Namn",
        nameInvalid: "Ange för- och efternamn.",

        // nps
        npsNotLikely: "Inte alls sannolikt",
        npsExtremelyLikely: "Mycket sannolikt",

        // numeric input
        numericInputRequired: "Obligatoriskt.",
        numericInputMustBeNumber: "Måste vara ett nummer.",
        numericInputMustBeGreaterThenMin: "Måste vara större än {min}.",
        numericInputMustBeLessThenMax: "Måste vara mindre än {max}.",
        numericInputMustBeInteger: "Måste vara ett heltal.",
        numericInputInvalidNumber: "Ogiltigt nummer.",
        numericInputPlaceholder: "Skriv nummer",

        // phone
        phoneInvalid: "Telefonnumret är ogiltigt.",
        phoneCountrySelectorLabel: 'Landskod',
        phoneCountrySelectorError: 'Välj land',
        phoneNumberLabel: 'Telefonnummer',
        phoneExample: 'Exempel:',

        // boolean
        booleanYesLabel: "Ja",
        booleanNoLabel: "Nej",

        //questionStep
        questionStepAudioQuestionLabel: "Ljudfråga",

        // errors
        invalidPhoneNumber: "{phone} är ett ogiltigt telefonnummer.",
        invalidEmail: "{email} är en ogiltig e-postadress.",
        questionIsRequired: "Frågan är obligatorisk.",
        answerIsNotValid: "Svaret är ogiltigt.",
        unfinishedProbingDialogError: "Vänligen avsluta dialogen.",
        cannotResumePartialResponse: "Kan inte återuppta ofullständigt svar.",
        failedToSubmitForm: "Misslyckades med att skicka formuläret. Kontrollera din internetanslutning och försök igen."
    }
}
